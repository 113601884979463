<template>
  <v-app class="bg">
    <v-row no-gutters class="fill-height d-flex justify-center align-center">
      <v-card min-width="300" width="50vw" max-width="1000" rounded="lg" raised>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-row class="fill-height align-center" no-gutters>
                <v-col>
                  <v-form ref="credentials">
                    <h3 class="text-center">Please Login First</h3>
                    <br />
                    <v-text-field
                      label="Username/Email"
                      v-model="form.data.email"
                      :rules="form.rules.email"
                    ></v-text-field>
                    <v-text-field
                      :type="password.type"
                      label="Password"
                      v-model="form.data.password"
                      :rules="form.rules.password"
                    >
                      <template v-slot:append-outer>
                        <v-btn icon @click="showPassword">
                          <v-icon>{{ password.icon }}</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                    <br />
                    <v-btn
                      large
                      depressed
                      color="indigo accent-2"
                      @click="handleLogin"
                      :loading="loading"
                      :disabled="loading"
                    >
                      <span class="white--text">login</span>
                      <v-icon right color="white">mdi-login</v-icon>
                    </v-btn>
                  </v-form>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="d-flex align-center">
              <v-img contain src="@/assets/images/welcome.png"></v-img>
            </v-col>
          </v-row>
          <v-alert dense type="error" prominent v-if="this.$route.query.message">
            {{ this.$route.query.message }}
          </v-alert>
        </v-card-text>
      </v-card>
    </v-row>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" multi-line>
      <v-icon left>
        {{ snackbar.icon }}
      </v-icon>
      {{ snackbar.text }}

      <template v-slot:action>
        <v-btn text @click="snackbar.show = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <Footer />
  </v-app>
</template>

<style scoped>
.bg {
  background: rgb(211, 195, 255);
  background: linear-gradient(
    112deg,
    rgba(211, 195, 255, 1) 0%,
    rgba(169, 247, 250, 1) 57%,
    rgba(255, 190, 223, 1) 100%
  );
}
</style>

<script>
import axios from "axios";

axios.defaults.withCredentials = true;
export default {
  data() {
    return {
      loading: false,
      form: {
        data: {
          email: null,
          password: null,
        },
        rules: {
          email: [
            (v) => !!v || "Email is required",
            (v) => /.+@.+\..+/.test(v) || "Email must be valid",
          ],
          password: [(v) => !!v || "Password is required"],
        },
      },
      password: {
        show: false,
        icon: "mdi-eye",
        type: "password",
      },
      snackbar: {
        show: false,
        color: null,
        text: null,
        icon: null,
      },
    };
  },
  components: {
    Footer: () => import("@/components/Footer.vue"),
  },
  methods: {
    showPassword() {
      this.password.show = !this.password.show;
      this.password.icon = this.password.show ? "mdi-eye-off" : "mdi-eye";
      this.password.type = this.password.show ? "text" : "password";
    },
    async handleLogin() {
      this.loading = true;

      let creds = this.form.data;

      if (this.$refs.credentials.validate()) {
        await axios
          .get(`${process.env.VUE_APP_API_URL}/sanctum/csrf-cookie`)
          .then((response) => {
            axios
              .post(`${process.env.VUE_APP_API_URL}/api/login`, creds)
              .then((response) => {
                console.log(response.status);
                window.localStorage.setItem("name", response.data[0].user.name);
                window.localStorage.setItem("role", response.data[0].user.role);
                window.localStorage.setItem("token", response.data[0].token);

                switch (response.status) {
                  case 200: {
                    this.showNotification("success");
                    this.loading = false;
                    this.$router.push({
                      name: "Dashboard",
                      path: "/console/dashboard",
                    });
                    break;
                  }
                }
              })
              .catch((err) => {
                console.log(err);
                this.showNotification("error");
                this.loading = false;
              });
            console.log(response.status);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.showNotification("error");
        this.loading = false;
      }
    },
    showNotification(status) {
      this.snackbar.show = true;
      switch (status) {
        case "success":
          this.snackbar.color = "success";
          this.snackbar.icon = "mdi-check";
          this.snackbar.text = "Login Successful";
          break;
        case "error":
          this.snackbar.color = "error";
          this.snackbar.icon = "mdi-close";
          this.snackbar.text = "Login Failed";
          break;
      }
    },
  },
};
</script>